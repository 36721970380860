import request from '@/router/axios';
import { pvhApiBase } from '@/config/env'
var pvhApiBases = "http://localhost:5202/s/";

export const listInfo = (name,  page, pageSize) => {
    return request({
        url: pvhApiBases+"Supplier/GetSettlementMethodList",
        method: "get",
        params: {
            "name": name,
            "page": page,
            "pageSzie": pageSize
        }
    })
}
export const addInfo = (row) => {
    return request({
        url: pvhApiBases + "Supplier/PostSettlementMethod",
        method: "post",
        data: {
            ...row
        }
    })
}

//
export const editInfo = (row) => {
    return request({
        url: pvhApiBases + "Supplier/PutSettlementMethod",
        method: "put",
        data: {
            ...row
        }
    })
}

//
export const delInfo = (id) => {
    return request({
        url: pvhApiBases + "Supplier/DeleteSettlementMethod",
        method: "delete",
        params: {
                id
        }
    })
}

export const editStatus = (row) => {
    return request({
        url: pvhApiBases + "Supplier/UpdateCertifiedStatus",
        method: "put",
        data: {
            BrandBusinessId:"",
            CertifiedId: row.id,
            Status: row.Status == 1 ? false : true
        }
    })
}