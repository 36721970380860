<template>
  <div class="pvhFactory">
    <basic-container>
      <div class="layTop">
        <div class="Title" style="margin-bottom:20px;">结算方式信息</div>
      </div>
      <template>
          <avue-crud ref="crud" v-model="obj" :option="setData.tableOpt" :data="tableData" :row-style="rowStyle"
                     :page.sync="page" @current-change="currentChange" @row-update="handleUpdate"
                     @row-del="del(row, index, done)" @row-save="handleSave">
              <template slot="menuLeft" style="width: calc(100% - 80px);">
                  <el-row :gutter="20" class="topFromSearch">
                      <el-col :xs="24" :sm="16" :md="16" :lg="19" :xl="20" class="fromOutDiv">
                          <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                              <span>结算方式:</span>
                              <el-input v-model="nameVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                          </el-col>
                      </el-col>
                      <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4"
                              style="padding-top: 25px; text-align: right; align-self: flex-end;">
                          <el-button :title="$t('Search')" icon="iconfont icon-textile-sousuo" size="mini" type="primary"
                                     @click="searchFrom">
                              {{ $t('Search') }}
                          </el-button>
                          <el-button type="primary" icon="iconfont icon-textile-tianjia" size="mini" :title="$t('New')" plain
                                     @click="$refs.crud.rowAdd()">
                              {{ $t('New') }}
                          </el-button>
                              <!--<el-button type="primary" icon="iconfont icon-textile-tianjia" size="mini" :title="$t('New')" plain
                               @click="newAdd">
                        {{ $t('New') }}
                    </el-button>-->

                      </el-col>
                  </el-row>
              </template>
              <template slot-scope="{row,index,size,type}" slot="menu">
                  <el-button icon="el-icon-edit-outline" type="text" size="medium" :title="$t('Edit')"
                             @click="$refs.crud.rowEdit(row,index)"></el-button>
                  <!--<el-button icon="el-icon-edit-outline" type="text" size="medium" :title="$t('Edit')"
        @click="isAdd=false;newAdd"></el-button>-->
                  <el-button icon="el-icon-delete-solid" type="text" size="medium" :title="$t('Delete')"
                             @click="del(row,index)"></el-button>
                  <!--<el-button icon="iconfont icon-vip353" :class="row.status===3?'primary':'no'" type="text" size="medium" :title="$t('Delete')" @click="tracingPur(row,index,size,type)"></el-button>-->
              </template>
              <template slot-scope="{row,index,type}" slot="menuForm">
                  <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" v-if="type=='add'"
                             @click="$refs.crud.rowSave()">
                      {{ $t('Hold') }}
                  </el-button>
                  <el-button type="primary" icon="el-icon-circle-check" size="small" v-if="type=='edit'"
                             @click="$refs.crud.rowUpdate()">
                      {{ $t('Hold') }}
                  </el-button>
                  <el-button icon="el-icon-circle-close" size="small" @click="$refs.crud.closeDialog()">
                      {{ $t('Cancel') }}
                  </el-button>
              </template>
              <template slot="settlementPeriodTypeForm" scope="scope" >
                  <div class="form">
                      <el-radio-group v-model="obj.settlementPeriodType">
                          <el-radio :label="0">
                              固定天数      结算期限
                              <div class="input-box"><input placeholder="" v-model="obj.settlementPeriodDays" /></div>
                              天
                          </el-radio>
                          <el-radio :label="1" >
                              固定月数     每月
                              <div class="input-box"><input placeholder="" v-model="obj.monthlySettlementDeadline" /></div>
                              日，结算
                              <div class="input-box"><input placeholder="" v-model="obj.howmanydaysBefore" /></div>
                              天前的待结算业务
                          </el-radio>
                      </el-radio-group>
                  </div>
               
              </template>
          </avue-crud>
      </template>
    </basic-container>



  </div>
</template>
<style>
</style>
<script>
import { listInfo, delInfo, editInfo, editStatus, addInfo, } from '@/api/supplier/settlementInfo'
import { ProcessDropDownList, TracingDropDownList, TracingUnifiedIsBool } from '@/api/unified'

export default {
  data () {
        return {
            obj: { settlementPeriodType: 0} ,
      nameVal: '',
      tableData: [],
      vendorData: [],
      dictData: [],
      vendorAddData: [],
      vendorvectionData: [],//供应商类别
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      page: {
        currentPage: 1,
        total: 0,
        layout: 'total,pager,prev,next',
        background: true,
        pageSize: 10
      }
    }
  },
  computed: {
    setData () {
      var validateExist = (rule, value, callback) => {
        if ((value != '' || value != null)) {
          var ids = this.obj.id
          if (this.obj.id == undefined) {
            ids = ''
          }
          TracingUnifiedIsBool('CertifiedNameExist', ids, value).then(resu => {
            var IsExist = resu.data
            if (!IsExist) {
              callback(new Error(this.$t('NameIsExist')))
            } else { callback() }
          }).catch(() => { callback() })
        } else {
          callback()
        }
      }
      var validateCropExist = (rule, value, callback) => {
        if ((value != '' || value != null)) {
          var ids = this.obj.id
          if (this.obj.id == undefined) {
            ids = ''
          }
          TracingUnifiedIsBool('CertifiedCropExist', ids, value).then(resu => {
            var IsExist = resu.data
            if (!IsExist) {
              callback(new Error(this.$t('CropIsExist')))
            } else { callback() }
          }).catch(() => { callback() })
        } else {
          callback()
        }
      }
      var validateCodeExist = (rule, value, callback) => {
        if ((value != '' || value != null)) {
          var ids = this.obj.id
          if (this.obj.id == undefined) {
            ids = ''
          }
          TracingUnifiedIsBool('CertifiedCodeExist', ids, value).then(resu => {
            var IsExist = resu.data
            if (!IsExist) {
              callback(new Error(this.$t('CodeIsExist')))
            } else { callback() }
          }).catch(() => { callback() })
        } else {
          callback()
        }
      }
      //如果所属于最后一级可见下级可为空
      var validateRequired = (rule, value, callback) => {
        var VSections = this.obj.vendorSections.toString()
        value = value.toString()
        if (this.obj.vendorSections == undefined) {
          VSections = ''
        }
        TracingUnifiedIsBool('AllowedVendorSectionsRequired', VSections, value).then(resu => {
          var IsExist = resu.data
          if (IsExist) {
            callback(new Error(this.$t('Required')))
          } else { callback() }
        }).catch(() => { callback() })
      }
      return {
        tableOpt: {
          page: true,
          refreshBtn: false,
          emptyText: this.$t('No Data'),
          menuTitle: this.$t('OPERATION'),
          menuWidth: 140,
          align: 'center',
          columnBtn: false,
          simplePage: false,
          editTitle: this.$t('Edit'),
          addTitle: this.$t('Add'),
          addBtn: false,
          editBtn: false,
          delBtn: false,
          header: true,
          //labelPosition: 'top',
          saveBtn: false,
          updateBtn: false,
          cancelBtn: false,
          column: [
            {
              label: '结算方式',// this.$t('CertifiedCode'),
                  prop: 'settlementMethod',
                  span: 24,
              placeholder: ' ',
              rules: [{ required: true, message: '', trigger: 'blur' }]
            },
            {
              label: '状态',
              type: 'radio',     //单选框
              prop: 'status',
                filterable: true,
                span: 24,//样式
              value: 0,
              dicData: [{
                label: '正常',
                value: 0
              }, {
                label: '禁用',
                value: 1
              }],
              placeholder: ' ',
              rules: [{ required: false, message: this.$t('Required'), trigger: 'blur' }]
            },
            {
              label: '结算周期',// this.$t('Supplier'),
                prop: 'settlementPeriodType',
                formslot: true,//允许自定义内容
                type: 'radio',
                span: 24,
                value: 0,
                dicData: [{
                    label: '固定天数',
                    value: 0
                }, {
                    label: '固定月数',
                    value: 1
                }],
              rules: [{ required: false, message: this.$t('Required'), trigger: 'blur' },
                { validator: validateCodeExist, trigger: 'blur' }
              ]
              },
              {
                  label: 'periodDays',
                  prop: "settlementPeriodDays",
                  hide: true,
                  value: 1,
                  addDisplay: false,
                  editDisplay: false,
              },
              {
                  label: 'deadline',
                  prop: "monthlySettlementDeadline",
                  hide: true,
                  value:1,
                  addDisplay: false,
                  editDisplay: false,
              },
              {
                  label: 'before',
                  prop: "howmanydaysBefore",
                  hide: true,
                  value: 1,
                  addDisplay: false,
                  editDisplay: false,
              },
            {
              label: '结算日期',
              prop: 'settlementDateType',
              type: 'radio',
              filterable: true,
              placeholder: ' ',
                value: 0,
                span: 24,
              dicData: [{
                label: '订单下达日',
                value: 0
              }, {
                label: '发票开具日期',
                value: 1
              }, {
                label: '发货日期',
                value: 2
              }],
              rules: [{ required: false, message: this.$t('Required'), trigger: 'blur' }]
            },
              {
                  label: '类型',
                  type: 'select',     //单选框
                  prop: 'type',
                  filterable: true,
                  span: 24,//样式
                  value: 0,
                  dicData: [{
                      label: '供应商',
                      value: 0
                  }, {
                      label: '客户',
                      value: 1
                  }],
                  placeholder: ' ',
                  rules: [{ required: false, message: this.$t('Required'), trigger: 'blur' }]
              },
          ]
        }
      }
    }
  },
  created () {
    this.getList()
    this.init()
  },
  methods: {
    init () {
      ProcessDropDownList('CorporationListNoBrand').then(res => {
        this.vendorData = res.data
      })
      ProcessDropDownList('DictList', 'brand').then(res => {
        this.dictData = res.data
      })
      TracingDropDownList('VendorSectionList').then(res => {
        this.vendorvectionData = res.data

      })
      TracingDropDownList('VendorAddress').then(res => {
        this.vendorAddData = res.data
      })
    },
    focusDiv (obj) {//搜索框获取焦点
      if (obj.currentTarget === undefined) {
        obj.$el.previousElementSibling.style.cssText = 'top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;'
      } else {
        obj.currentTarget.parentElement.previousElementSibling.style.cssText = 'top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;'
      }
    },//搜索框失去焦点
    blurDiv (obj) {
      if (obj.currentTarget === undefined) {
        if (obj.value === '') {
          obj.$el.previousElementSibling.style.cssText = 'top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266'
        }
      } else if (obj.currentTarget === null) {
        if (this.supplierVal.ids.length === 0) {
          document.querySelector('#supplierSpan').style.cssText = 'top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266'
        }
        if (this.allSectionsVal.ids.length === 0) {
          document.querySelector('#allSpan').style.cssText = 'top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266'
        }
      } else {
        if (obj.target.value == '') {
          obj.currentTarget.parentElement.previousElementSibling.style.cssText = 'top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266'
        }
      }
    },
    supplierchang () {
      if (this.supplierVal.ids.length === 0) {
        document.querySelector('#supplierSpan').style.cssText = 'top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266'
      } else {
        document.querySelector('#supplierSpan').style.cssText = 'top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;'
      }
    },//
    allchang () {
      if (this.allSectionsVal.ids.length === 0) {
        document.querySelector('#allSpan').style.cssText = 'top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266'
      } else {
        document.querySelector('#allSpan').style.cssText = 'top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;'
      }
    },//
    rowStyle ({ rowIndex }) {
      if (rowIndex % 2 === 0) {
        return {
          backgroundColor: '#F8F8F8'
        }
      }
    },//切换分页
    currentChange (val) {
      this.page.currentPage = val
      this.getList()
    },//搜索
    searchFrom () {
      this.getList()
    },//编辑数据
    handleUpdate (row, index, done) {
      editInfo(row).then(() => {
        this.$message({ showClose: true, message: this.$t('UpdateSuccess'), type: 'success' })
        this.getList()
        this.obj = { settlementPeriodType: 0 };
      })
      done()
    },
    //},//删除数据
    del (row, index, done) {
      this.$confirm(
          this.$t('IsDel') , this.$t('Tips'), {
            confirmButtonText: this.$t('Yes'),
            cancelButtonText: this.$t('Cancel'),
            type: 'warning'
          }).then(() => {
        delInfo(row.id).then(() => {
          this.getList()
          this.$message({ showClose: true, message: this.$t('DeleteSuccess'), type: 'success' })
          done()
        })
      })
      },
     //新增数据 
      handleSave(row, done) {
          addInfo(row).then(() => {
              this.$message({ showClose: true, message: this.$t('AddSuccess'), type: "success" });
              this.getList();
              this.obj = { settlementPeriodType: 0 } ;
              done();
          }).catch(() => { });
      },
    //显示数据
    async getList () {
        await listInfo(this.nameVal, this.page.currentPage, this.page.pageSize).then(res => {
            var dataSource = res.data.settlementMethods
            this.tableData = dataSource
        this.page.total = res.data.totalCount
        this.page.pageSize = res.data.pageSize
      }).catch(erro => {
        console.log(erro)
      })
    },
    //认证状态
    tracingPur (row) {
      this.$message({ showClose: true, message: this.$t('online'), type: 'warning' })
      // editStatus(row);
    },
   
  }
}
</script>

<style>
.form .input-box {
  display: inline-block;
  border-bottom: 1px solid;
  width: 20px;
  padding-left: 5px;
  padding-right: 5px;
}

.form .input-box input {
  border: none;

}
</style>
